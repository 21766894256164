<template>
    <div id="CollectionIndex" v-if="projectsData[0]">
        <div class="main main-project">
            <div class="section section-title">
                <div class="title" data-aos="fade-up">
                    <h1>專案</h1>
                    <span>Project</span>
                </div>
            </div>
            <div class="section section-gallery">
                <ul>
                    <li
                        v-for="(item, index) in projectsData"
                        :key="'project' + index"
                        data-aos="flip-left"
                        :data-aos-delay="300 + index * 100"
                    >
                        <router-link
                            :to="{
                                name: 'Project',
                                params: { projectTitle: item.projectTitle },
                            }"
                        >
                            <h2>{{ item.projectTitle }}</h2>
                            <div
                                class="picture"
                                :style="addBackgroundImage(item.pictureURL)"
                            ></div>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
        <div class="main main-course">
            <div class="section section-title">
                <div class="title" data-aos="fade-up">
                    <h1>課程</h1>
                    <span>Course</span>
                </div>
            </div>
            <div class="section section-gallery">
                <ul>
                    <li
                        data-aos="flip-left"
                        :data-aos-delay="300 + index * 100"
                    >
                        <router-link
                            :to="{
                                name: 'Course',
                            }"
                        >
                            <h2>程式設計入門</h2>
                            <div class="picture"></div>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue-demi'
import { getProjectsAPI } from '../../service/api.js'
import { addBackgroundImage } from '../../service/util.js'

export default {
    name: 'CollectionIndex',
    setup() {
        let projectsData = reactive([])
        onMounted(async () => {
            await getProjectsAPI().then((res) => {
                projectsData.push(...res.data)
            })
        })

        return { projectsData, addBackgroundImage }
    },
}
</script>

<style lang="scss" scoped>
.main {
    position: relative;
}

.section-title {
    .title {
        text-align: left;
    }
}
.section-gallery {
    ul {
        li {
            box-shadow: $shadow-box;
            a {
                text-decoration: none;
                h2 {
                    @include h2Setting();
                    padding-top: 0.5em;
                    line-height: 3em;
                }
                .picture {
                    background: top center / cover no-repeat;
                }
            }
        }
    }
}
.main-course {
    .section-gallery {
        li {
            .picture {
                background-image: url('~@/assets/Course/index-picture.jpg');
            }
        }
    }
}

@media screen and (min-width: 640px) {
    .section {
        margin: 80px auto;
        max-width: 1440px;
        width: 80%;
    }
    .section-title {
        margin-bottom: 40px;
    }
    .section-gallery {
        margin-top: 40px;
        ul {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 30px;
            li {
                .picture {
                    height: 30vw;
                }
            }
        }
    }
}
@media screen and (max-width: 960px) and (min-width: 640px) {
    .section-gallery {
        ul {
            grid-template-columns: repeat(2, 1fr);
            li {
                .picture {
                    height: 40vw;
                }
            }
        }
    }
}

@media screen and (max-width: 639px) {
    .section {
        margin: 60px auto;
        max-width: 1440px;
        width: 80%;
    }
    .section-title {
        margin-bottom: 0;
    }
    .section-gallery {
        margin-top: 0;
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    ul {
        display: flex;
        padding: 10px 10%;
        width: fit-content;

        li {
            margin: 0 3vw;
            width: 70vw;
            .picture {
                height: 40vh;
            }
        }
    }
}
</style>
